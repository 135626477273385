const plataforma = "&plataforma=WEB";
const empresa = `&empresa=${process.env.REACT_APP_EMPRESA}`;
const platemp = `?${plataforma}${empresa}`;
export const empresaId = process.env.REACT_APP_EMPRESA;

// const base = "http://localhost:4000";
// const base = "http://54.149.61.100:4000";
const base = "https://api.medpal.com.ec/app";

export const urlBucket = `${process.env.REACT_APP_BUCKET}`;
export const urlSocket = base;
// export const urlSocket ='https://bacmedpal.herokuapp.com';

// Ulr base
const urlBaseReservas = `${base}/api/citas`;

// Auth
export const urlRegistro = `${base}/api/auth/medicos/registrar${platemp}`;
export const urlInstitucionRegistro = `${base}/api/auth/institucion/registrar${platemp}`;

export const urlLoginDoctor = `${base}/api/auth/medicos/login${platemp}`;
export const urlActivarCuentaUsuario = `${base}/api/auth/activate-user-account/`;
export const urlUpdateEmail = `${base}/api/auth/changeEmail/`;
export const urlVerificarResetKey = `${base}/api/auth/verificarResetKey/`;
export const urlActualizarPasswordPorResetKey = `${base}/api/auth/changePassword/`;
export const urlCurrentUser = `${base}/api/auth/currentUser`;
export const urlEnviarFormContacto = `${base}/api/clientes/enviarFormularioContacto`;
export const urlCrearNuevoRol = `${base}/api/auth/newRole`;
export const urlSolicitarNuevoRol = `${base}/api/auth/requestNewRole`;
export const urlResetPass = `${base}/api/auth/resetPassword${platemp}`;
export const urlStartResetPass = `${base}/api/auth/startResetPassword${platemp}`;
export const urlChangePass = `${base}/api/auth/change-password${platemp}`;
export const urlOficinas = `${base}/api/oficinas/`;
export const urlOficinasDoctor = `${base}/api/oficinas/doctor`;
export const urlOficinasLaboratorista = `${base}/api/oficinas/lab/`;
export const urlHorariosOficinas = `${base}/api/oficinas/horarios/`;
export const urlHorariosOficinasLaboratorista = `${base}/api/oficinas/horarios/laboratorista/`;
export const urlDeleteHorariosOficinas = `${base}/api/oficinas/horario/`;
export const urlHorariosReservas = `${base}/api/oficinas/horario-reserva/`;
export const urlHorariosReservasLaboratorista = `${base}/api/oficinas/horario-reserva/laboratorista/`;
export const urlGetClientes = `${base}/api/clientes/`;
export const urlSetClientes = `${base}/api/clientes/add/`;
export const urlSetClientesValoresBiometricos = `${base}/api/clientes/actualizarValoresBiometricos/`;
export const urlSetClientesAntecedentes = `${base}/api/clientes/actualizarAntecedentes/`;
export const urlMedicos = `${base}/api/medicos/`;
export const urlClientes = `${base}/api/clientes/`;
export const urlGetMedicos = `${base}/api/medicos/getMedicos/`;
export const urlSecretaria = `${base}/api/secretaria/`;
export const urlLaboratorista = `${base}/api/laboratorista/`;
export const urlBuscarMed = `${base}/api/vademecum/`;
export const urlBuscarMedDoctor = `${base}/api/vademecum/doctor/`;
export const urlEspecialidades = `${base}/api/especializacion/`;
export const urlRecetas = `${base}/api/recetas/`;
export const urlRecetasSendMail = `${urlRecetas}/enviarRecetaClienteEmail${platemp}`;
export const urlRecetasSignSendMail = `${urlRecetas}/sendSignedRecipeToClientEmail`;
export const urlRecetasSignDownload = `${urlRecetas}/downloadSignedRecipe`;
export const urlGetNumeroCajasReservasSecretaria = `${base}/api/cajas/getNumeroReservasCajasPorSecretaria`;
export const urlGetCajasReservasbySecretaria = `${base}/api/cajas/getReservasCajasPorSecretaria`;

// Reservas
export const urlListarCitas = `${base}/api/citas/`;
export const urlListarCitasOficina = `${base}/api/citas/oficina/`;
export const urlSaveReservationPaidState = `${urlBaseReservas}/confirmarEstadoPagado/`;

export const urlListarCitasAnterior = `${base}/api/citas/get_reservas_por_medico${platemp}&id=`;
export const urlUpdateCitaEstadoDisponible = `${base}/api/citas/setDisponible/`;
export const urlUpdateEstadoCita = `${base}/api/citas/setEstado/`;
export const urlCancelReservaDoctor = `${base}/api/citas/cancelarReservaDoctor/`;
export const urlUpdateRazonNoDisponibilidad = `${base}/api/citas/setRazonNoDisponibilidad/`;
export const urlHorariosOficinasAnterior = `${base}/api/oficinas/get_horario_oficinas${platemp}&id=`;
export const urlOficinasAnterior = `${base}/api/oficinas/get_oficina${platemp}&id=`;
export const urlSetOficinasAnterior = `${base}/api/oficinas/crear_oficina${platemp}`;
export const urlSetHorariosOficinasAnterior = `${base}/api/oficinas/crear_horario_oficinas${platemp}`;
export const urlListarCajas = `${base}/api/cajas/`;
export const urlGetCie10 = `${base}/api/cie10/`;
export const urlGetVacunas = `${base}/api/medicos/buscarVacunas`;
export const urlGetExamenes = `${base}/api/medicos/buscarExamenes`;

//Admin
const urlAdmin = `${base}/api/admin`;

// Auth
export const urlRequestAccountDeletion = `${base}/api/auth/requestAccountDeletion`;
export const urlConfirmAccountDeletion = `${base}/api/auth/confirmAccountDeletion`;
export const urlConfirmLoginAccountDeletion = `${base}/api/auth/confirmLoginAccountDeletion`;
// TODO: Cambiar al metodo anterior
export const urlConfirmAccountDeletionByUserId = `${base}/api/auth/confirmAccountDeletionUserId`;

//Empresa
export const urlEmpresa = `${base}/api/empresa`;

// Pacientes
export const urlListarPacientesAtendidos = `${base}/api/medicos/getPacientesAtendidos`;
export const urlEditarPacientesAtendido = `${base}/api/medicos/editarPacienteAtendido`;
export const urlEliminarPacientesAtendido = `${base}/api/medicos/deletePacienteAtendido`;

//Institucion
export const urlSearchInstituciones = `${base}/api/institucion/buscarInstituciones`;
export const urlInsitucionGuardarPerfil = `${base}/api/institucion/guardarPerfil`;
export const urlLoadInstitucionAsociaciones = `${base}/api/institucion/obtenerAsociacionesOficinas`;
export const urlLoadInstitucionSolicitudes = `${base}/api/institucion/obtenerAsociacionesOficinasSolicitudes`;
export const urlInstitucionAceptarAsociacion = `${base}/api/institucion/confirmarAsociacionSolicitud`;
export const urlInstitucionEliminarAsociacion = `${base}/api/institucion/eliminarAsociacion`;
export const urlInstitucionEliminarSolicitudAsociacion = `${base}/api/institucion/eliminarSolicitudAsociacion`;
export const urlInstitucionObtenerSucursales = `${base}/api/institucion/obtenerSucursales`;
export const urlInstitucionOficinasEspecialidades = `${base}/api/institucion/oficinas/especialidadesOptions`;


//CRUD
export const platempExp = `?${plataforma}${empresa}`;
//Titulos
export const urlListarTitulos = `${base}/api/titulos`;
export const urlCrearTitulo = `${base}/api/titulos`;
export const urlEditarTitulo = `${base}/api/titulos`;

//CIE10
export const urlListarCIE10 = `${base}/api/cie10`;
export const urlCrearCIE10 = `${base}/api/cie10`;
export const urlEditarCIE10 = `${base}/api/cie10`;
export const urlEliminarCIE10 = `${base}/api/cie10`;

//Doctores
export const urlDoctorCrearConsulta = `${base}/api/medicos/crearConsulta`;
export const urlListarDoctorClienteConsultas = `${base}/api/medicos/obtenerConsultasPorCliente`;
export const urlHistorialMedico = `${base}/api/medicos/obtenerHistorialMedico`;
export const urlDoctorConsultaParametros = `${base}/api/medicos/obtenerConsultaParametros`;
export const urlDoctorSolicitarAsociacionOficinaInstitucion = `${base}/api/medicos/solicitarAsociacionDoctorOficinaInstitucion`;
export const urlDoctorObtenerDatosInstitucionesAsociaciones = `${base}/api/medicos/obtenerDatosInstitucionesAsociaciones`;
export const urlDoctorOficinasSucursales = `${base}/api/medicos/oficinasSucursales`;
export const urlDoctorEliminarOficinaAfiliacion = `${base}/api/medicos/eliminarAfiliacionOficina`;

export const urlDoctorCrearReserva = `${base}/api/medicos/crearReserva`;

//DoctoresPromociones
export const urlAdminListarDoctoresPromociones = `${base}/api/medicos/promociones/getAll`;
export const urlAdminEditarDoctorServicio = `${base}/api/medicos/promociones/edit`;
export const urlAdminEliminarDoctorServicio = `${base}/api/medicos/promociones/delete`;

//Doctores Servicios
export const urlListarDoctoresServicios = `${base}/api/medicos/obtenerServicios`;
export const urlCrearServicio = `${base}/api/medicos/crearServicio`;
export const urlEditarServicio = `${base}/api/medicos/editarServicio`;
export const urlEliminarServicio = `${base}/api/medicos/eliminarServicio`;
export const urlAsignarServicioOficina = `${base}/api/medicos/asignarServicioOficina`;

//Doctor certificado medico
export const urlDoctorCertificateSignDownload = `${urlMedicos}downloadMedicalCertificatePDF`;
export const urlDoctorEmailCertificate = `${urlMedicos}sendEmailMedicalCertificatePDF`;

//Vacunas
export const urlConsultaListarPacienteVacunas = `${base}/api/medicos/consultaListarPacienteVacunas`;
export const urlConsultaCrearPacienteVacuna = `${base}/api/medicos/consultaCrearPacienteVacuna`;
export const urlConsultaActualizarPacienteVacuna = `${base}/api/medicos/consultaActualistarPacienteVacuna`;
export const urlConsultaEliminarPacienteVacuna = `${base}/api/medicos/consultaEliminarPacienteVacuna`;

//Examenes
export const urlEditarExamenPaciente = `${base}/api/medicos/editarExamenPaciente`;
export const urlEditarGrupoExamenPaciente = `${base}/api/medicos/editarGrupoExamenPaciente`;
export const urlCrearExamenListaPredeterminada = `${base}/api/medicos/crearExamenListaPredeterminada`;
export const urlEditarExamenListaPredeterminada = `${base}/api/medicos/editarExamenListaPredeterminada`;
export const urlEliminarExamenListaPredeterminada = `${base}/api/medicos/eliminarExamenListaPredeterminada`;
export const urlEnviarEmailPedidoExamenes = `${urlMedicos}sendEmailExamRequestPDF`;

//Especializacion
export const urlListarEspecializaciones = `${base}/api/especializacion/crud/getAll`;
export const urlCrearEspecializacion = `${base}/api/especializacion/crud/create`;
export const urlEditarEspecializacion = `${base}/api/especializacion/crud/edit`;
export const urlEliminarEspecializacion = `${base}/api/especializacion/crud/delete`;

//Especializacion Categorias
export const urlListarCategoriasEspecializaciones = `${base}/api/especializacion/categoria/crud/getAll`;
export const urlCrearCategoriaEspecializacion = `${base}/api/especializacion/categoria/crud/create`;
export const urlEditarCategoriaEspecializacion = `${base}/api/especializacion/categoria/crud/edit`;
export const urlEliminarCategoriaEspecializacion = `${base}/api/especializacion/categoria/crud/delete`;

//Reservas
export const urlListarReservas = `${base}/api/citas`;
export const urlCrearReserva = `${base}/api/citas`;
export const urlEditarReserva = `${base}/api/citas/edit`;
export const urlEliminarReserva = `${base}/api/citas`;

//Oficinas
export const urlSetOficinasPut = `${base}/api/oficinas/`;
export const urlInstitucionGuardarOficina = `${base}/api/oficinas/guardarOficinaInstitucion`;
export const urlDoctorGuardarOficina = `${base}/api/oficinas/guardarOficinaDoctor`;
export const urlListarOficinas = `${base}/api/oficinas`;
export const urlCrearOficina = `${base}/api/oficinas`;
export const urlEditarOficina = `${base}/api/oficinas/edit`;
export const urlEliminarOficina = `${base}/api/oficinas/delete`;
export const urlListarHorariosPorOficina = `${base}/api/oficinas/get/horarios/oficina`;
export const urlSetHorarioByOficina = `${base}/api/oficinas/set/horarios/`;

//Recetas
export const urlListarRecetas = `${base}/api/recetas`;
export const urlCrearReceta = `${base}/api/recetas`;
export const urlEditarReceta = `${base}/api/recetas`;
export const urlEliminarReceta = `${base}/api/recetas`;

export const urlCrearRecetaDetalle = `${base}/api/recetas/detalle`;
export const urlEditarRecetaDetalle = `${base}/api/recetas/detalle`;
export const urlEliminarRecetaDetalle = `${base}/api/recetas/detalle`;

//Usuarios
export const urlListarUsuarios = `${base}/user/getAll`;
export const urlObtenerUsuarioPorId = `${base}/user/getUserById`;
export const urlEditarUsuario = `${base}/user/edit`;
export const urlEliminarUsuario = `${base}/user/delete`;
export const urlAgregarRolUsuario = `${base}/user/addRole`;
export const urlEliminarRolUsuario = `${base}/user/deleteRole`;

// Roles
export const urlValidateRole = `${base}/api/auth/validateRole`;

//Vademecum
export const urlListarVademecum = `${base}/api/vademecum/crud/getAll`;
export const urlCrearVademecum = `${base}/api/vademecum/crud/create`;
export const urlEditarVademecum = `${base}/api/vademecum/crud/edit`;
export const urlEliminarVademecum = `${base}/api/vademecum/crud/delete`;

//Sitios Recomendados
export const urlListarSitiosRecomedados = `${base}/api/sitiosRecomendados`;
export const urlCrearSitioRecomedados = `${base}/api/sitiosRecomendados`;
export const urlEditarSitioRecomedados = `${base}/api/sitiosRecomendados`;
export const urlEliminarSitioRecomedados = `${base}/api/sitiosRecomendados`;

//Transacciones
export const urlListarTransacciones = `${base}/api/transaccion`;
export const urlCrearTransaccion = `${base}/api/transaccion`;
export const urlEditarTransaccion = `${base}/api/transaccion`;
export const urlEliminarTransaccion = `${base}/api/transaccion`;

//Ciudades
export const urlListarCiudades = `${base}/api/ciudad/crud/getAll`;
export const urlCrearCiudad = `${base}/api/ciudad/crud/create`;
export const urlEditarCiudad = `${base}/api/ciudad/crud/edit`;
export const urlEliminarCiudad = `${base}/api/ciudad/crud/delete`;
export const urlBuscarCiudades = `${base}/api/ciudad/search`;

//Ciudades Especialiacion Afiliacion
export const urlListarCiudadesEspecializacionAfiliacion = `${base}/api/ciudad/crud/afiliacionEspecializacionPorCiudad/getAll`;
export const urlCrearCiudadEspecializacionAfiliacion = `${base}/api/ciudad/crud/afiliacionEspecializacionPorCiudad/create`;
export const urlEditarCiudadEspecializacionAfiliacion = `${base}/api/ciudad/crud/afiliacionEspecializacionPorCiudad/edit`;
export const urlEliminarCiudadEspecializacionAfiliaciom = `${base}/api/ciudad/crud/afiliacionEspecializacionPorCiudad/delete`;

//Seguros
export const urlgetSegurosPorMedico = `${base}/api/seguros/getForMedico`;
export const urlSeguro = `${base}/api/seguros/`;
export const urlGetSegurosBusqueda = `${base}/api/seguros/search`;

// Seguros Admin
export const urlListarSeguros = `${base}/api/seguros`;
export const urlCrearSeguro = `${base}/api/seguros/`;
export const urlEditarSeguro = `${base}/api/seguros/`;
export const urlEliminarSeguro = `${base}/api/seguros/`;

// Contactos
export const urlListarContactos = `${base}/api/contacto`;
export const urlEliminarContacto = `${base}/api/contacto`;
export const urlResponderContacto = `${base}/api/contacto/responderContacto`;
export const urlEnviarMensajeAyuda = `${base}/user/enviarMensajeAyuda`;

// Notificaciones
export const urlListarNotificacionesAdmin = `${base}/api/notificacion/getNotificacionesAdmin${platemp}`;
export const urlEliminarNotificacionAdmin = `${base}/api/notificacion/eliminarNotificacionAdmin`;
export const urlCrearNotificacionPushGlobal = `${base}/api/notificacion/crearNotificacionPushGlobal`;
export const urlNotificacionesInstitucion = `${base}/api/notificacion/institucion`;

// Noticias
export const urlListarNoticias = `${base}/api/noticias/admin/getAll`;
export const urlCrearNoticia = `${base}/api/noticias/admin/create`;
export const urlEditarNoticia = `${base}/api/noticias/admin/update`;
export const urlEliminarNoticia = `${base}/api/noticias/admin/delete`;
export const urlNoticiaImagen = `${base}/api/noticias/image/upload`;

// Blogs
export const urlListarBlogs = `${base}/api/blogs/admin/getAll`;
export const urlCrearBlog = `${base}/api/blogs/admin/create`;
export const urlEditarBlog = `${base}/api/blogs/admin/update`;
export const urlEliminarBlog = `${base}/api/blogs/admin/delete`;

//Paypal
export const urlConfirmarSubscripcion = `${base}/api/paypal/confirmSubscription`;
export const urlGetSubscripcion = `${base}/api/paypal/getUserSubscriptionDetails`;
export const urlCancelarSubscripcion = `${base}/api/paypal/cancelUserSubscription`;

//Paises
export const urlGetPaisesBusqueda = `${base}/api/pais/search`;

//Créditos
export const urlGetCreditItem = `${base}/api/clientes/creditItem`;

// Reservas pago
export const urlVerificarReserva = `${base}/api/citas/verificarReserva`;
export const urlVerificarReservaCreada = `${base}/api/citas/verificarPagoReservaCreada`;

//Payphone
export const urlPayphoneButton = 'https://pay.payphonetodoesposible.com/api/button/js?appId='
export const urlSavePayphoneConfig = `${base}/api/medicos/savePayhoneConfig`
export const urlConfirmTransaction = `${base}/api/pagos/confirmTransaction`

// Facturación
// Facturación doctor
export const urlFacturacionConfigParams = `${base}/api/medicos/facturacionParametros`
export const urlCreateFacturacionConfigParams = `${base}/api/medicos/crearFacturacionParametros`
export const urlFirmarFacturaElectronicaConsulta = `${base}/api/medicos/consulta/firmarFacturaElectronica`
export const urlFacturaConsulta = `${base}/api/medicos/consulta/factura`
export const urlFacturaConsultaEnviarSRI = `${base}/api/medicos/consulta/factura/enviarSRI`;
export const urlFacturaConsultaSendReceipt = `${base}/api/medicos/consulta/factura/enviarSRIRecepcion`;

// Facturación secretaria
export const urlSecretariaFirmarFacturaElectronicaConsulta = `${base}/api/secretaria/consulta/firmarFacturaElectronica`
export const urlSecretariaFacturaConsulta = `${base}/api/secretaria/consulta/factura`
export const urlSecretariaFacturaConsultaEnviarSRI = `${base}/api/secretaria/consulta/factura/enviarSRI`;
export const urlSecretariaFacturaConsultaSendReceipt = `${base}/api/secretaria/consulta/factura/enviarSRIRecepcion`;


//Admin routes
// vacunas
export const urlAdminVacunas = `${urlAdmin}/vacunas`;
export const urlAdminExamen = `${urlAdmin}/examen`;
export const urlAdminCliente = `${urlAdmin}/cliente`;
export const urlAdminInstitucion = `${urlAdmin}/institucion`;
export const urlAdminSecretaria = `${urlAdmin}/secretaria`;
export const urlAdminLaboratorista = `${urlAdmin}/laboratorista`;
export const urlAdminDoctor = `${urlAdmin}/doctor`;
export const urlAdminTransaction = `${urlAdmin}/transaction`;