import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Notificacion from "components/ui/Notificacion";
import { getClienteConsultas } from "redux/actions/aClientes";
import { crearConsulta, startLoadHistoriaClinica } from "redux/actions/aMedico";
import { useRecetaForm } from "./useRecetaForm";

export const useConsultaForm = ({
  pageRef,
  cliente_id,
  setReceta,
  handleClickNext,
  handleSetMedidas,
  handleSetCie10,
  handleSetAlergias,
  handleSetMedicinas,
  oficinaSelected,
  servicioSelected,
}) => {

  const dispatch = useDispatch();

  // Data
  // Receta data
  const {
    // Cie10
    cie10Data,
    setCie10Data,
    // Vademecum
    vademecumData,
    setVademecumData,
    errorVademecum,

    // Receta
    includesReceta,
    setIncludesReceta,
    recetaFormErrors,
    recetaFormValues,
    setRecetaFormValues,

    getRecetaFormData
  } = useRecetaForm();


  //React hook form
  const { control, errors, getValues, watch, reset, trigger, setValue, formState: { isDirty }, } = useForm();

  //Antecendentes
  const [antecedentesFormDirty, setAntecedentesFormDirty] = useState(false);

  //Vacunas
  const [vacunasFormDirty, setVacunasFormDirty] = useState(false);

  const [examenesIsDirty, setExamenesIsDirty] = useState(false);
  const [examenesUpdated, setExamenesUpdated] = useState([]);
  const [examenesGrupoUpdated, setExamenesGrupoUpdated] = useState([]);

  const [recetaDirty, setRecetaDirty] = useState(false);

  //Cie10
  const [cie10SelectedList, setCie10SelectedList] = useState([]);

  //Certificado 
  const [certificadoFormDirty, setCertificadoFormDirty] = useState(false);

  // Index de arreglo de consultas (-1: empty form) 
  const [consultaSelectedIndex, setConsultaSelectedIndex] = useState(-1);

  //Verificar si el usuario manipuló el formulario
  const formHistoriaMedicaDirty = useMemo(
    () =>
      ((examenesIsDirty || isDirty || recetaDirty) && consultaSelectedIndex === -1)
      || antecedentesFormDirty || vacunasFormDirty || certificadoFormDirty,
    [isDirty, examenesIsDirty, consultaSelectedIndex, recetaDirty, antecedentesFormDirty, vacunasFormDirty, certificadoFormDirty]);

  const formConsultaDirty = useMemo(
    () => (examenesIsDirty || isDirty || recetaDirty) && consultaSelectedIndex === -1,
    [examenesIsDirty, isDirty, recetaDirty, consultaSelectedIndex]);

  const [onConsultaPageOn, setOnConsultaPageOn] = useState(true);

  const cliente_data_state = useSelector((state) => state.data_clientes.data);

  //Cliente data formateada
  const cliente_data = useMemo(() => (cliente_data_state && cliente_data_state.consultas) ? cliente_data_state : { ...cliente_data_state, 'consultas': { data: [] } }, [cliente_data_state])

  //Consulta seleccionadad por el selector de fecha
  const consultaSelected = useMemo(() => (cliente_data && (cliente_data.consultas.data.length > consultaSelectedIndex) && (consultaSelectedIndex > -1)) ? cliente_data.consultas.data[consultaSelectedIndex] : null, [cliente_data, consultaSelectedIndex]);
  const receta = useMemo(() => consultaSelected ? consultaSelected.receta : null, [consultaSelected]);
  const lastReceta = useMemo(() => cliente_data && cliente_data.consultas.data.length > 0 ? cliente_data.consultas.data[0].receta : null, [cliente_data]);
  const examenesGrupoMedico = useMemo(() => cliente_data_state && cliente_data_state.doctorGruposExamen ? cliente_data_state.doctorGruposExamen : [], [cliente_data_state]);

  const [recetaData, setRecetaData] = useState(null);

  // Oficinas data
  // const [oficinas, setOficinas] = useState([]);
  // const [servicios, setServicios] = useState([]);
  // const [oficinaSelected, setOficinaSelected] = useState(null);
  // const [servicioSelected, setServicioSelected] = useState(null);
  const [oficinaError, setOficinaError] = useState(null);

  //Loading
  const [loadingConsultas, setloadingConsultas] = useState(true);
  const [loadingPagination, setLoadingPagination] = useState(false);

  //Filter: 0: todas las consultas, 1: consultas con exámenes
  const [filterByExams, setFilterByExams] = useState(null);

  // Paginacion
  const skipAdd = useRef(15);
  const [skipValue, setSkipValue] = useState(skipAdd.current);

  //Modal
  //Modal Warning
  const [openModalWarning, setopenModalWarning] = useState(false);
  const [openModalWarningOnLeavePage, setOpenModalWarningOnLeavePage] = useState(false);

  // Date seccion signos vitales
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [disabledArrowForwardButton, setDisabledArrowForwardButton] = useState(true);
  const [disabledArrowBackButton, setDisabledArrowBackButton] = useState(true);

  // Float menu collapse
  const [collapseDateOptions, setCollapseDateOptions] = useState(true);

  //Acordion signos vitales/consulta
  const [openAccordionAntecedentes, setOpenAccordionAntecedentes] = useState(false);
  const [openAccordionVacunas, setOpenAccordionVacunas] = useState(false);
  const [openAccordion34, setOpenAccordion34] = useState(false);

  //Accordion signos vitales
  const [openAccordion3, setOpenAccordion3] = useState(false);
  //Accordion examenes
  const [openAccordion4, setOpenAccordion4] = useState(false);
  //Accordion diagnostico
  const [openAccordion5, setOpenAccordion5] = useState(false);

  //Accordion exámenes
  const [openAccordionExamenes, setOpenAccordionExamenes] = useState(false);

  // Accordions, click open/close actions
  const handleOpenAccordions = (accordionNumber) => {
    let openAccordionSelectedState = false;
    let setOpenAccordionSelected = null;
    let accordionIndex = null;
    switch (accordionNumber) {
      case 3: //Signos Vitales y Antropometría
        openAccordionSelectedState = openAccordion3;
        setOpenAccordionSelected = setOpenAccordion3;
        accordionIndex = 3;
        break;
      case 4: //Consulta
        openAccordionSelectedState = openAccordion4;
        setOpenAccordionSelected = setOpenAccordion4;
        accordionIndex = 4;
        break;
      case 5: //Diagnóstico
        openAccordionSelectedState = openAccordion5;
        setOpenAccordionSelected = setOpenAccordion5;
        accordionIndex = 5;
        break;
      case 6: //Examenes
        setOpenAccordionExamenes(!openAccordionExamenes)
        accordionIndex = 6;
        return

      default:
        break;
    }

    if (!openAccordionSelectedState && (accordionIndex === 3 || accordionIndex === 4)) {
      setOpenAccordion34(true);
      setOpenAccordion3(true);
      setOpenAccordion4(true);
      setOpenAccordion5(true);
    } else if ((!openAccordionSelectedState) && (accordionIndex === 5)) {
      setOpenAccordion34(true);
      setOpenAccordion3(true);
      setOpenAccordionSelected(true);
    } else if (!openAccordionSelectedState) setOpenAccordionSelected(true);
    else setOpenAccordionSelected(false);

  }

  useEffect(() => {
    if (receta) setRecetaData(receta);
    else setRecetaData(null);
  }, [receta]);

  useEffect(() => {
    if (!(openAccordion3 || openAccordion4 || openAccordion5)) setOpenAccordion34(false);
  }, [openAccordion3, openAccordion4, openAccordion5])


  //examenes
  const [examenes, setExamenes] = useState([]);
  const [examenesGrupo, setExamenesGrupo] = useState([]);

  // Actualizar campos de formulario de consulta
  const updateFormConsulta = useCallback(
    (data) => {
      const dataFormat = {
        ...data,
        'sv_pulso': data.sv_pulso ?? "",
        'sv_pres_sist': data.sv_pres_sist ?? "",
        'sv_pres_dias': data.sv_pres_dias ?? "",
        'sv_temp': data.sv_temp ?? "",
        'sv_frec_resp': data.sv_frec_resp ?? "",
        'sv_oximetria': data.sv_oximetria ?? "",
        'antropometria_peso': data.antropometria_peso ?? "",
        'antropometria_talla': data.antropometria_talla ?? "",

      }
      reset(dataFormat);
      setExamenesIsDirty(false);
    },
    [reset],
  )

  const loadData = useCallback(
    async () => {
      await dispatch(startLoadHistoriaClinica(cliente_id, 0, skipAdd.current));
      setloadingConsultas(false);
    },
    [dispatch, cliente_id],
  )

  //Reset form section 3
  const resetAllFields = useCallback(
    () => {
      setConsultaSelectedIndex(-1);
      setDisabledArrowForwardButton(true);
      setRecetaData(null);
      setRecetaDirty(false);

      setSelectedDate(new Date());
      const dataInit = {
        "sv_pulso": "",
        "sv_pres_sist": "",
        "sv_pres_dias": "",
        "sv_temp": "",
        "sv_frec_resp": "",
        "sv_oximetria": "",
        "antropometria_peso": "",
        "antropometria_talla": "",
        "motivo": "",
        "enfermedad_actual": "",
        "exa_organos_sentidos": null,
        "exa_respiratorio": null,
        "exa_cardiovascular": null,
        "exa_digestivo": null,
        "exa_genital": null,
        "exa_urinario": null,
        "exa_musculo_esqueletico": null,
        "exa_endocrino": null,
        "exa_hemo_linfatico": null,
        "exa_nervioso": null,
        "exa_organos_sistemas_desc": "",
        "exa_cabeza": null,
        "exa_cuello": null,
        "exa_torax": null,
        "exa_abdomen": null,
        "exa_pelvis": null,
        "exa_extremidades": null,
        "exa_fisico_regional_desc": "",
        "plan_tratamiento": "",
      }
      reset(dataInit);
    },
    [reset],
  );

  //Load data
  useEffect(() => {
    loadData()
  }, [dispatch, loadData]);

  //Load data consultas
  useEffect(() => {
    if (filterByExams !== null) {
      setloadingConsultas(true);
      dispatch(getClienteConsultas(cliente_id, 0, skipAdd.current, filterByExams))
        .then(() => {
          setloadingConsultas(false)
          setSkipValue(skipAdd.current);
          resetAllFields();
        })
    }
  }, [filterByExams, cliente_id, dispatch, resetAllFields])

  useEffect(() => {
    if (cliente_data && cliente_data.consultas && consultaSelectedIndex !== -1) {
      updateFormConsulta(cliente_data.consultas.data[consultaSelectedIndex])
      setSelectedDate(new Date(cliente_data.consultas.data[consultaSelectedIndex].created_date))
    } else if (cliente_data && cliente_data.consultas && cliente_data.consultas.data.length > 0 && consultaSelectedIndex === -1) {
      setDisabledArrowBackButton(false);
    }
  }, [cliente_data, updateFormConsulta, consultaSelectedIndex]);

  useEffect(() => {
    if (consultaSelectedIndex !== -1) setRecetaDirty(false);
  }, [consultaSelectedIndex])


  //Verify isDirty for clear date
  useEffect(() => {
    if (isDirty && !examenesIsDirty) {
      //If form is dirty, restart date pickers and buttons state
      setConsultaSelectedIndex(-1);
      setDisabledArrowForwardButton(true);
      setSelectedDate(new Date());
    }
  }, [isDirty, examenesIsDirty]);


  //Reset fecha del formulario de consulta a fecha actual
  const resetConsultaDate = () => {
    setConsultaSelectedIndex(-1);
    setDisabledArrowForwardButton(true);
    setSelectedDate(new Date());
  }

  // Reiniciar estado del formulario de exámen órganos y sistemas
  const handleResetExamenOrganosSistemas = () => {
    setConsultaSelectedIndex(-1);
    setDisabledArrowForwardButton(true);
    setSelectedDate(new Date());
    setValue("exa_organos_sentidos", null);
    setValue("exa_respiratorio", null);
    setValue("exa_cardiovascular", null);
    setValue("exa_digestivo", null);
    setValue("exa_genital", null);
    setValue("exa_urinario", null);
    setValue("exa_musculo_esqueletico", null);
    setValue("exa_endocrino", null);
    setValue("exa_hemo_linfatico", null);
    setValue("exa_nervioso", null);
    setValue("exa_organos_sistemas_desc", "");
  }

  // Reiniciar estado del formulario de exámen fisico regional
  const handleResetExamenFisicoRegional = () => {
    setConsultaSelectedIndex(-1);
    setDisabledArrowForwardButton(true);
    setSelectedDate(new Date());
    setValue("exa_cabeza", null);
    setValue("exa_cuello", null);
    setValue("exa_torax", null);
    setValue("exa_abdomen", null);
    setValue("exa_pelvis", null);
    setValue("exa_extremidades", null);
    setValue("exa_fisico_regional_desc", "");
  }

  // Setear o reiniciar estado de exámenes
  useEffect(() => {
    if (consultaSelectedIndex === -1 && !examenesIsDirty) {
      setExamenes([]);
      setExamenesGrupo([]);
    }
  }, [consultaSelectedIndex, examenesIsDirty]);

  useEffect(() => {
    if (consultaSelectedIndex !== -1) {
      setExamenes(cliente_data.consultas?.data[consultaSelectedIndex].examenes)
      setExamenesGrupo(cliente_data.consultas?.data[consultaSelectedIndex].examenesGrupo)
    }

  }, [consultaSelectedIndex, cliente_data])

  // Actualizar arreglo global de exámenes
  const updateExamenes = useCallback(
    (data, newExamen) => {
      setExamenesIsDirty(true);
      const newExamenesUpdated = examenesUpdated.filter(item => item.id !== newExamen.id);
      if (newExamen) setExamenesUpdated([...newExamenesUpdated, newExamen]);
      // Evitar reinicio de form en use effect Update examenes
      setExamenes(data);
      setDisabledArrowForwardButton(true);
      setSelectedDate(new Date());
    },
    [examenesUpdated],
  );

  // Actualizar arreglo global de grupo exámenes
  const updateGrupoExamenes = useCallback(
    (data, newExamenGrupo) => {
      setExamenesIsDirty(true);
      const newExamenesGrupoUpdated = examenesGrupoUpdated.filter(item => item.id !== newExamenGrupo.id);
      if (newExamenGrupo) setExamenesGrupoUpdated([...newExamenesGrupoUpdated, newExamenGrupo]);

      // Evitar reinicio de form en use effect Update examenes
      setExamenesGrupo(data);
      setConsultaSelectedIndex(-1);
      setDisabledArrowForwardButton(true);
      setSelectedDate(new Date());
    },
    [examenesGrupoUpdated],
  );

  // Watch form variables
  const pulso = watch('sv_pulso');
  const presSist = watch('sv_pres_sist');
  const presDias = watch('sv_pres_dias');
  const temp = watch('sv_temp');
  const frecResp = watch('sv_frec_resp');
  const oximetria = watch('sv_oximetria');
  const peso = watch('antropometria_peso');
  const talla = watch('antropometria_talla');

  const calculateIMC = useCallback(
    (peso, talla) => {
      try {
        if (!peso || !talla || isNaN(peso) || isNaN(talla) || Number(talla) === 0) return '';
        return `${(Number(peso) / Math.pow(Number(talla) / 100, 2)).toFixed(2)}`
      } catch (error) {
        return ''
      }
    },
    [],
  );

  const IMC = useMemo(() => calculateIMC(peso, talla), [peso, talla, calculateIMC])


  const handleDateChange = (date) => {
    let index = null;
    let data = null;
    let arrayLength = 0;

    if (cliente_data && cliente_data.consultas) {
      arrayLength = cliente_data.consultas.data.length
      for (let i = 0; i < arrayLength; i++) {
        const item = cliente_data.consultas.data;
        const dateToCompare = item[i].created_date;
        if (moment(dateToCompare).isSame(date, 'day')) {
          index = i;
          data = item[i];
          break;
        }
      }
    }

    if (index === null) return;

    setSelectedDate(new Date(data.created_date))
    setConsultaSelectedIndex(index);

    if (index >= 0 && index <= arrayLength - 1 && index !== 0 && index !== arrayLength - 1) {
      setDisabledArrowForwardButton(false)
      setDisabledArrowBackButton(false);
    } else if (index === 0 && index === arrayLength - 1) {
      setDisabledArrowForwardButton(true)
      setDisabledArrowBackButton(true);
    } else if (index === 0) {
      setDisabledArrowForwardButton(true)
      setDisabledArrowBackButton(false);

    } else if (index === arrayLength - 1) {
      setDisabledArrowForwardButton(false)

      if (!cliente_data.consultas.hasMoreData) {
        setDisabledArrowBackButton(true);
      }
    }
  }

  //Accion del boton selector de fecha
  const handleClickArrowButton = async (action = 0) => {
    if (!cliente_data || !cliente_data.consultas || (cliente_data.consultas.data.length <= consultaSelectedIndex)) return;
    let arrayLength = cliente_data.consultas.data.length;

    let index = consultaSelectedIndex;
    let setDisabledForwardButton = setDisabledArrowForwardButton;
    let setDisabledBackButton = setDisabledArrowBackButton;

    if (action === 1 && index > 0) {
      // Siguiente consulta, habilitar y deshabilitar botones del selector de fecha
      if ((consultaSelectedIndex - 1) === 0) {
        setDisabledArrowForwardButton(true);
        setDisabledArrowBackButton(false);
      } else if ((consultaSelectedIndex - 1) !== 0) {
        setDisabledArrowForwardButton(false);
        setDisabledArrowBackButton(false);
      }

      setConsultaSelectedIndex(consultaSelectedIndex - 1);


    } else if (action === 0 && index < (arrayLength - 1)) {
      // Consulta anterior, habilitar y deshabilitar botones del selector de fecha
      if (index === -1 && arrayLength > 0) {
        if (arrayLength !== 1) {
          setDisabledBackButton(false);
          setDisabledForwardButton(true);
        } else setDisabledBackButton(true);

      } else if ((consultaSelectedIndex + 1) === (arrayLength - 1)) {
        setDisabledArrowForwardButton(false);
        if (!cliente_data.consultas.hasMoreData) setDisabledArrowBackButton(true);

      } else if ((consultaSelectedIndex + 1) !== (arrayLength - 1)) {
        setDisabledArrowBackButton(false);
        setDisabledArrowForwardButton(false);
      }

      setConsultaSelectedIndex(consultaSelectedIndex + 1)
    } else if (action === 0 && cliente_data.consultas.hasMoreData && ((consultaSelectedIndex + 1) === arrayLength)) {
      // Paginación, cargar mas data de consultas cuando el índice es igual al límite del arreglo de consultas
      setLoadingPagination(true);
      const { transaccion, data } = await dispatch(getClienteConsultas(cliente_id, skipValue, skipAdd.current, filterByExams === 1 ? 1 : 0));
      if (!transaccion || !data) return

      let newLength = data?.data.length + arrayLength;

      //Deshabilitar botón atras cuando ya no existan datos de consultas por cargar
      if ((consultaSelectedIndex + 1) === (newLength - 1) && !data.hasMoreData) setDisabledArrowBackButton(false);

      setSkipValue(skipValue + skipAdd.current);
      setConsultaSelectedIndex(consultaSelectedIndex + 1)
      setLoadingPagination(false);
    }

  };

  const handleDeleteForm = () => resetAllFields();

  const onSubmit = async (onSumbitCheck) => {
    //Guardar consulta
    try {
      if (!oficinaSelected) {
        Notificacion({
          type: "error",
          text: "Debe seleccionar una oficina.",
        });
        setOficinaError('Debe seleccionar una oficina');
        return;
      }

      const formValues = await getConsultaValues();
      if (!formValues) return;
      const dataReceta = await getRecetaFormData();

      if (!isDirty && receta?.cie10?.length === 0 && receta?.detalle.length === 0 && examenes.length === 0 && examenesGrupo.length === 0) {
        onSumbitCheck();
        return;
      }

      if (dataReceta?.receta) {
        dataReceta.receta = {
          ...dataReceta.receta,
        }
      } else dataReceta.receta = {}

      let imc = '';
      if (formValues.antropometria_peso && formValues.antropometria_talla) {
        imc = parseFloat(formValues.antropometria_peso) / parseFloat(Math.pow(parseFloat(formValues.antropometria_talla) / 100, 2))
      }

      dataReceta.receta = {
        ...dataReceta.receta,
        peso: formValues.antropometria_peso,
        talla: formValues.antropometria_talla,
        imc,
      }

      const dataToSave = {
        ...formValues,
        ...dataReceta,
        cliente_id,
        examenes,
        examenesGrupo,
        oficinaId: oficinaSelected,
        servicioId: servicioSelected && (servicioSelected !== 0 && servicioSelected !== "0") ? servicioSelected : null,
      };

      // console.log({ dataToSave })
      // return;

      pageRef.current.scrollIntoView();
      setOpenAccordionAntecedentes(false);
      setOpenAccordionVacunas(false);
      setloadingConsultas(true);

      const { transaccion, mensaje, data } = await dispatch(crearConsulta(dataToSave));

      if (!transaccion) throw (new Error(mensaje))

      // Enviar datos al estado del stepper
      setReceta({
        ...data.receta,
        consultaId: data.id,
      })
      handleSetMedidas(dataToSave.receta);
      handleSetCie10(dataToSave.receta?.cie10 ?? []);
      handleSetAlergias(dataToSave.receta?.alergias);
      handleSetMedicinas(dataToSave.receta?.detalle ?? [])

      setloadingConsultas(false);
      setOnConsultaPageOn(false);
      handleClickNext();

      Notificacion({
        type: "success",
        text: "Datos guardados correctamente.",
      });

    } catch (error) {
      setloadingConsultas(false);
      console.log(error)
    }
  }

  const onError = () => {
    Notificacion({
      type: "error",
      text: "Existen campos no válidos en el formulario de la consulta.",
    });
  }

  // Receta Reutilización
  const handleReuseLastReceta = () => {
    setConsultaSelectedIndex(-1);
    setDisabledArrowForwardButton(true);
    setSelectedDate(new Date());
  }

  // Verificar valores de form consultas
  const getConsultaValues = async () => {
    const formValues = getValues({ nest: true });

    //trigger validation on fields 
    const value = await trigger();
    if (value) return formValues;
    else {
      onError();
      return null;
    }
  }

  return {
    //Props
    cie10SelectedList,
    cliente_data_state,
    collapseDateOptions,
    disabledArrowBackButton,
    disabledArrowForwardButton,
    examenes,
    examenesGrupo,
    examenesGrupoMedico,
    examenesGrupoUpdated,
    examenesUpdated,
    filterByExams,
    formConsultaDirty,
    formHistoriaMedicaDirty,
    lastReceta,
    loadingConsultas,
    loadingPagination,
    onConsultaPageOn,
    openAccordion3,
    openAccordion34,
    openAccordion4,
    openAccordion5,
    openAccordionAntecedentes,
    openAccordionExamenes,
    openAccordionVacunas,
    openModalWarning,
    openModalWarningOnLeavePage,
    receta,
    recetaData,
    recetaDirty,
    selectedDate,
    oficinaError,
    consultaSelected,

    //Methods
    handleClickArrowButton,
    handleDateChange,
    handleDeleteForm,
    handleOpenAccordions,
    handleResetExamenFisicoRegional,
    handleResetExamenOrganosSistemas,
    handleReuseLastReceta,
    onSubmit,
    resetConsultaDate,
    setAntecedentesFormDirty,
    setCertificadoFormDirty,
    setCie10SelectedList,
    setCollapseDateOptions,
    setFilterByExams,
    setloadingConsultas,
    setOpenAccordionAntecedentes,
    setOpenAccordionVacunas,
    setopenModalWarning,
    setOpenModalWarningOnLeavePage,
    setRecetaDirty,
    setVacunasFormDirty,
    updateExamenes,
    updateGrupoExamenes,

    // React hook form
    control,
    errors,
    pulso,
    presSist,
    presDias,
    temp,
    frecResp,
    oximetria,
    peso,
    talla,
    IMC,

    // Receta
    // Cie10
    cie10Data,
    setCie10Data,
    // Vademecum
    vademecumData,
    setVademecumData,
    errorVademecum,

    // Receta
    includesReceta,
    setIncludesReceta,
    recetaFormErrors,
    recetaFormValues,
    setRecetaFormValues,

    getRecetaFormData,
  }
}
